// custom typefaces
import "typeface-merriweather"
import "typeface-montserrat"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"

import "./src/styles/global.scss"
import "react-toastify/dist/ReactToastify.css"
import "react-creative-cursor/dist/styles.css"

import gsap from "gsap"

// Global config for GSAP
if (typeof window !== "undefined") {
    gsap.config({
        // remove null target in warning console
        nullTargetWarn: false
    })
}

// import React from "react"
// import { ThemeProvider } from "./src/context/ThemeContext"

// // Dynamically load the polyfill
// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }

// //Darkmode

// export const wrapRootElement = ({ element }) => (
//   <ThemeProvider>{element}</ThemeProvider>
// )
